import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/index.js';

// 基础模块
import layout from '@/view/pages/layout/layout';

// 首页
import login from '@/view/pages/login/index';
import index from '@/view/pages/index/index';
import dashboard from '@/view/pages/dashBoard/index';
import landManage from '@/view/pages/landManage/index';
import landDetail from '@/view/pages/landManage/detail';
import editLand from '@/view/pages/landManage/editLand';
import technologyManage from '@/view/pages/technologyManage/index';
import technologyList from '@/view/pages/technologyManage/list';
import mettingManage from '@/view/pages/mettingManage/index';
import trainingManage from '@/view/pages/trainingManage/index';
import machineManage from '@/view/pages/machineManage/index';
import machineList from '@/view/pages/machineManage/list';
import detailService from '@/view/pages/serviceDetail/index';
import plantingPlant from '@/view/pages/plantingPlant/index';
import farming from '@/view/pages/plantingPlant/farming';
import relatedPlots from '@/view/pages/plantingPlant/relatedPlots';
import land from '@/view/pages/plantingPlant/land';
import questionManage from '@/view/pages/questionManage/index';
import articleManage from '@/view/pages/articleManage/index';
import mavinManage from '@/view/pages/mavinManage/index';
import uavManage from '@/view/pages/uavManage/index';
import uavList from '@/view/pages/uavManage/list';
import supplyManage from '@/view/pages/supplyManage/index';
import buyManage from '@/view/pages/buyManage/index';
import stockinManage from '@/view/pages/stockinManage/index';
import stockManage from '@/view/pages/stockManage/index';
import stockManage1 from '@/view/pages/stockManage1/index';
import stockManage2 from '@/view/pages/stockManage2/index';
import stockManage3 from '@/view/pages/stockManage3/index';
import stockManage4 from '@/view/pages/stockManage4/index';
import homework from '@/view/pages/plantingPlant/homework';
import screenLand from '@/view/pages/screenLand/index';
import supplyManageAgriculture from '@/view/pages/supplyManageAgriculture/index';
import farmWorkList from '@/view/pages/farmWork/index';
import cropCategory from '@/view/pages/cropCategory/index';
import cropSubCategory from '@/view/pages/cropCategory/category';
import business from '@/view/pages/businessManage/business';
import custom from '@/view/pages/businessManage/custom';
import department from '@/view/pages/businessManage/department';
import role from '@/view/pages/businessManage/role';
import screen from '@/view/pages/screen/index';
import maintenanceManage from '@/view/pages/maintenanceManage/index';
import sqlList from '@/view/pages/maintenanceManage/sqlList';
import farmList1 from '@/view/pages/farmWork/farmList1';
import repair from '@/view/pages/message/repair';
import repairById from '@/view/pages/message/repairById';
import landMessage from '@/view/pages/message/landMessage';
import pest from '@/view/pages/insectAttack/pest';
import pest1 from '@/view/pages/insectAttack/pest1';
import pestRepair from '@/view/pages/insectAttack/repair';
import pestCheck from '@/view/pages/insectAttack/check';
import pestRepair1 from '@/view/pages/insectAttack/repair1';
import uavList1 from '@/view/pages/uavManage/uavList';
import cropManage from '@/view/pages/cropManage/index';
import operationRecord from '@/view/pages/operationRecord/index'; //操作记录
import situation from '@/view/pages/situation/index'; //四情
import situation1 from '@/view/pages/situation/index1'; //四情-new
import szsw1 from '@/view/pages/szsw/szsw1'; //各阀门情况
import szsw2 from '@/view/pages/szsw/szsw2'; //当前区域实时水质监测数据
import szsw3 from '@/view/pages/szsw/szsw3'; //当前水渠水位
import szsw4 from '@/view/pages/szsw/szsw4'; //当前区域设备状态
import szsw5 from '@/view/pages/szsw/szsw5'; //水质变化曲线图
import szsw6 from '@/view/pages/szsw/szsw6'; //水位变化曲线图
import szsw7 from '@/view/pages/szsw/szsw7'; // 蓝湖-环境监测数据
import wurenji from '@/view/pages/wurenji/index'; //无人机
import qxList from '@/view/pages/iotPlant/qxList'; //气象列表
import trList from '@/view/pages/iotPlant/trList'; //土壤列表
import cqList from '@/view/pages/iotPlant/cqList'; //虫情列表
import mqList from '@/view/pages/iotPlant/mqList'; //苗情列表
import cropYield from '@/view/pages/cropYield/index'; //作物产量
import hydropower from '@/view/pages/hydropower/index'; //用水统计报表
import njgl1 from '@/view/pages/njgl/index'; //农机管理
import njgl2 from '@/view/pages/njgl/nongjishou'; //农机手管理
import njgl3 from '@/view/pages/njgl/nongji'; //农机手管理
import njgl4 from '@/view/pages/njgl/nongjiLog'; //围栏日志
import njgl5 from '@/view/pages/njgl/fenceList'; //围栏列表
import elFence from '@/view/pages/njgl/fence'; //围栏列表

Vue.use(Router);

let router = new Router({
  routes: [
    {
      path: '/login',
      component: login,
      name: 'login'
    },
    {
      path: '/screenLand',
      component: screenLand,
      name: 'screenLand'
    },
    {
      path: '/customer',
      component: layout,
      children: [
        {
          path: 'uavTaskList',
          component: () => import('@/view/pages/uav/task/index'), //无人机任务管理
          name: 'UavTaskList',
          meta: { title: '无人机任务列表', noCache: true }
        },
        {
          path: 'uavTaskView',
          component: () => import('@/view/pages/uav/taskView/index'), //无人机巡田结果
          name: 'UavTaskView',
          meta: { title: '无人机巡田结果', noCache: true }
        },
        {
          path: 'uavTaskResult',
          component: () => import('@/view/pages/uav/task/result'), //无人机巡田结果
          name: 'UavTaskResult',
          meta: { title: '无人机巡田结果', noCache: true }
        },
        {
          path: 'uavTest',
          component: () => import('@/view/pages/uav/test/index'), //无人机巡田结果
          name: 'uavTest',
          meta: { title: '无人机巡田结果', noCache: true }
        }
      ]
    },
    {
      path: '/',
      component: layout,
      redirect: '/landList',
      children: [
        // 面板页
        {
          path: '/dashboard',
          component: dashboard,
          name: 'dashboard'
        },
        // 地块管理
        {
          path: '/landManage',
          component: landManage,
          name: 'landManage'
        },
        // 地块详情
        {
          path: '/landDetail',
          component: landDetail,
          name: 'landDetail'
        },
        // 编辑地块
        {
          path: '/editLand',
          component: editLand,
          name: 'editLand'
        },
        //科技管理
        {
          path: '/technologyManage',
          component: technologyManage,
          name: 'technologyManage'
        },
        //科技管理列表
        {
          path: '/technologyList',
          component: technologyList,
          name: 'technologyList'
        },
        //农机管理
        {
          path: '/machineManage',
          component: machineManage,
          name: 'machineManage'
        },
        //农机申请列表
        {
          path: '/machineList',
          component: machineList,
          name: 'machineList'
        },
        //会议管理
        {
          path: '/mettingManage',
          component: mettingManage,
          name: 'mettingManage'
        },
        //培训管理
        {
          path: '/trainingManage',
          component: trainingManage,
          name: 'trainingManage'
        },

        //详情
        {
          path: '/detailService',
          component: detailService,
          name: 'detailService'
        },
        //种植方案管理
        {
          path: '/plantingPlant',
          component: plantingPlant,
          name: 'plantingPlant'
        },
        //种植方案管理-农事
        {
          path: '/farming',
          component: farming,
          name: 'farming'
        },
        //种植方案管理-关联地块
        {
          path: '/relatedPlots',
          component: relatedPlots,
          name: 'relatedPlots'
        },
        //种植方案管理-农事
        {
          path: '/land',
          component: land,
          name: 'land'
        },
        //种植方案管理-农事
        {
          path: '/questionManage',
          component: questionManage,
          name: 'questionManage'
        },
        //文章管理
        {
          path: '/articleManage',
          component: articleManage,
          name: 'articleManage'
        },
        //专家管理
        {
          path: '/mavinManage',
          component: mavinManage,
          name: 'mavinManage'
        },
        //无人机管理
        {
          path: '/uavManage',
          component: uavManage,
          name: 'uavManage'
        },
        //无人机列表
        {
          path: '/uavList',
          component: uavList,
          name: 'uavList'
        },
        //供应管理
        {
          path: '/supplyManage',
          component: supplyManage,
          name: 'supplyManage'
        },
        //求购管理
        {
          path: '/buyManage',
          component: buyManage,
          name: 'buyManage'
        },
        //求购管理
        {
          path: '/stockinManage',
          component: stockinManage,
          name: 'stockinManage'
        },
        //库存管理
        {
          path: '/stockManage',
          component: stockManage,
          name: 'stockManage'
        },
        {
          path: '/stockManage1',
          component: stockManage1,
          name: 'stockManage1'
        },
        {
          path: '/stockManage2',
          component: stockManage2,
          name: 'stockManage2'
        },
        {
          path: '/stockManage3',
          component: stockManage3,
          name: 'stockManage3'
        },
        {
          path: '/stockManage4',
          component: stockManage4,
          name: 'stockManage4'
        },
        {
          path: '/analysisRes',
          component: () => import('@/view/pages/stockManage4/analysisRes'),
          name: 'analysisRes'
        },
        //作业表
        {
          path: '/homework',
          component: homework,
          name: 'homework'
        },
        // 农事操作记录
        {
          path: '/farmWorkList',
          component: farmWorkList,
          name: 'farmWorkList'
        },
        //文档管理
        {
          path: '/doc',
          component: () => import('@/view/pages/doc/index'),
          name: 'doc'
        },
        //农产品需求
        {
          path: '/supplyManageAgriculture',
          component: supplyManageAgriculture,
          name: 'supplyManageAgriculture'
        },
        {
          path: '/farmMachinery',
          component: () => import('@/view/pages/njgl/njgl'), //农机管理-设备管理,
          name: 'farmMachinery'
        },
        {
          path: '/cropCategory',
          component: cropCategory,
          name: 'cropCategory'
        },
        {
          path: '/cropSubCategory',
          component: cropSubCategory,
          name: 'cropSubCategory'
        },
        {
          path: '/role',
          component: role,
          name: 'role'
        },
        {
          path: '/department',
          component: department,
          name: 'department'
        },
        {
          path: '/custom',
          component: custom,
          name: 'custom'
        },
        {
          path: '/business',
          component: business,
          name: 'business'
        },
        {
          path: '/screen',
          component: screen,
          name: 'screen'
        },
        // 运维管理
        {
          path: '/maintenanceManage',
          component: maintenanceManage,
          name: 'maintenanceManage'
        },
        {
          path: '/sqlList',
          component: sqlList,
          name: 'sqlList'
        },
        {
          path: '/farmList1',
          component: farmList1,
          name: 'farmList1'
        },
        {
          path: '/repair',
          component: repair,
          name: 'repair'
        },
        {
          path: '/pest',
          component: pest,
          name: 'pest'
        },
        {
          path: '/pest1',
          component: pest1,
          name: 'pest1'
        },
        {
          path: '/pestRepair',
          component: pestRepair,
          name: 'pestRepair'
        },
        {
          path: '/pestRepair1',
          component: pestRepair1,
          name: 'pestRepair1'
        },
        {
          path: '/pestCheck',
          component: pestCheck,
          name: 'pestCheck'
        },
        {
          path: '/uavList1',
          component: uavList1,
          name: 'uavList1'
        },
        {
          path: '/cropManage',
          component: cropManage,
          name: 'cropManage'
        },
        {
          path: '/repairById',
          component: repairById,
          name: 'repairById'
        },
        {
          path: '/landMessage',
          component: landMessage,
          name: 'landMessage'
        },
        {
          path: '/operationRecord',
          component: operationRecord,
          name: 'operationRecord'
        },
        {
          path: '/situation',
          component: situation,
          name: 'situation'
        },
        {
          path: '/situation1',
          component: situation1,
          name: 'situation1'
        },
        {
          path: '/szsw1',
          component: szsw1,
          name: 'szsw1'
        },
        {
          path: '/szsw2',
          component: szsw2,
          name: 'szsw2'
        },
        {
          path: '/szsw3',
          component: szsw3,
          name: 'szsw3'
        },
        {
          path: '/szsw4',
          component: szsw4,
          name: 'szsw4'
        },
        {
          path: '/szsw5',
          component: szsw5,
          name: 'szsw5'
        },
        {
          path: '/szsw6',
          component: szsw6,
          name: 'szsw6'
        },
        {
          path: '/szsw7',
          component: szsw7,
          name: 'szsw7'
        },
        {
          path: '/szsw8',
          component: () => import('@/view/pages/szsw/szsw8'), // 自动生成方案
          name: 'szsw8'
        },
        {
          path: '/wurenji',
          component: wurenji,
          name: 'wurenji'
        },
        {
          path: '/deviceList',
          component: () => import('@/view/pages/deviceList/index'),
          name: 'deviceList'
        },
        {
          path: '/deviceList1',
          component: () => import('@/view/pages/deviceList/index1'),
          name: 'deviceList1'
        },
        {
          path: '/deviceList2',
          component: () => import('@/view/pages/deviceList/index2'),
          name: 'deviceList2'
        },
        {
          path: '/deviceList3',
          component: () => import('@/view/pages/deviceList/index3'),
          name: 'deviceList3'
        },
        {
          path: '/deviceList4',
          component: () => import('@/view/pages/deviceList/index4'),
          name: 'deviceList4'
        },
        {
          path: '/qxList',
          component: qxList,
          name: 'qxList'
        },
        {
          path: '/trList',
          component: trList,
          name: 'trList'
        },
        {
          path: '/cqList',
          component: cqList,
          name: 'cqList'
        },
        {
          path: '/mqList',
          component: mqList,
          name: 'mqList'
        },
        {
          path: '/cropYield',
          component: cropYield,
          name: 'cropYield'
        },
        {
          path: '/hydropower',
          component: hydropower,
          name: 'hydropower'
        },
        {
          path: '/njgl1',
          component: njgl1,
          name: 'njgl1'
        },
        {
          path: '/njgl2',
          component: njgl2,
          name: 'njgl2'
        },
        {
          path: '/njgl3',
          component: njgl3,
          name: 'njgl3'
        },
        {
          path: '/njgl4',
          component: njgl4,
          name: 'njgl4'
        },
        {
          path: '/njgl5',
          component: njgl5,
          name: 'njgl5'
        },
        {
          path: '/elFenceList',
          component: njgl5,
          name: 'elFenceList'
        },
        {
          path: '/elFence',
          component: elFence,
          name: 'elFence'
        },
        {
          path: '/xuqiuStatistics',
          component: () => import('@/view/pages/supplyManageAgriculture/xuqiu'), //农产品在线交易1-1发布需求统计
          name: 'xuqiuStatistics'
        },
        {
          path: '/buyStatistics',
          component: () => import('@/view/pages/supplyManageAgriculture/buy'), //订单农业电子商务1-2求购统计
          name: 'buyStatistics'
        },
        {
          path: '/supplyStatistics',
          component: () => import('@/view/pages/supplyManageAgriculture/supply'), //订单农业电子商务1-1供应统计
          name: 'supplyStatistics'
        },
        {
          path: '/viewStatistics',
          component: () => import('@/view/pages/supplyManageAgriculture/view'), //农产品在线交易1-3浏览人次统计
          name: 'viewStatistics'
        },
        {
          path: '/phoneStatistics',
          component: () => import('@/view/pages/supplyManageAgriculture/phone'), //农产品在线交易1-2获取手机号统计
          name: 'phoneStatistics'
        },
        {
          path: '/cqyc',
          component: () => import('@/view/pages/moxing/cqyc'), //虫情预测
          name: 'cqyc'
        },
        {
          path: '/zaihai',
          component: () => import('@/view/pages/moxing/zaihai'), //灾害
          name: 'zaihai'
        },
        {
          path: '/weatherDisaster',
          component: () => import('@/view/pages/moxing/weatherDisaster1'), //天气灾害
          name: 'weatherDisaster'
        },
        {
          path: '/binghai',
          component: () => import('@/view/pages/moxing/binghai'), //灾害
          name: 'binghai'
        },
        {
          path: '/cqfx',
          component: () => import('@/view/pages/moxing/cqfx'), //虫情分析
          name: 'cqfx'
        },
        {
          path: '/weatherModel',
          component: () => import('@/view/pages/moxing/weatherModel'), //天气模型
          name: 'weatherModel'
        },
        {
          path: '/soilModel',
          component: () => import('@/view/pages/moxing/soilModel'), //土壤模型
          name: 'soilModel'
        },
        {
          path: '/soilElementModel',
          component: () => import('@/view/pages/moxing/soilElementModel'), //土壤元素模型
          name: 'soilElementModel'
        },
        {
          path: '/szlxt',
          component: () => import('@/view/pages/moxing/szlxt'), //虫情分析
          name: 'szlxt'
        },
        {
          path: '/pestRepair2',
          component: () => import('@/view/pages/insectAttack/repair2'), //虫情
          name: 'pestRepair2'
        },
        {
          path: '/chatRoom',
          component: () => import('@/view/pages/chatRoom/index'), //
          name: 'chatRoom'
        },
        {
          path: '/fuWuShangManage',
          component: () => import('@/view/pages/fuWuShangManage/index'), // 服务商管理
          name: 'fuWuShangManage'
        },
        {
          path: '/goodsManage',
          component: () => import('@/view/pages/goodsManage/index'), // 商品管理
          name: 'goodsManage'
        },
        {
          path: '/orderManage',
          component: () => import('@/view/pages/orderManage/index'), // 订单管理
          name: 'orderManage'
        },
        {
          path: '/orderDetail',
          component: () => import('@/view/pages/orderManage/detail'), // 订单详情
          name: 'orderDetail'
        },
        {
          path: '/financeManage',
          component: () => import('@/view/pages/financeManage/index'), // 财务管理
          name: 'financeManage'
        },
        {
          path: '/sjggUser',
          component: () => import('@/view/pages/sjggUser/index'), // 水价改革用户
          name: 'sjggUser'
        },
        {
          path: '/cardBalance',
          component: () => import('@/view/pages/cardBalance/index'), //用卡余额
          name: 'cardBalance'
        },
        {
          path: '/cardBalanceDetail',
          component: () => import('@/view/pages/cardBalance/detail'), //用卡余额详情
          name: 'cardBalanceDetail'
        },
        {
          path: '/generateScheme',
          component: () => import('@/view/pages/generateScheme/index'), // 自动生成方案
          name: 'generateScheme'
        },
        {
          path: '/deviceManage',
          component: () => import('@/view/pages/deviceManage/index'), //平移设备管理
          name: 'deviceManage'
        },
        {
          path: '/pointerManage',
          component: () => import('@/view/pages/deviceManage/pointerManage'), //指针设备管理
          name: 'pointerManage'
        },
        {
          path: '/pumpManage',
          component: () => import('@/view/pages/deviceManage/pumpManage'), //泵站设备管理
          name: 'pumpManage'
        },
        {
          path: '/pumpMonitor',
          component: () => import('@/view/pages/deviceManage/pumpMonitor'), //泵站软启监视
          name: 'pumpMonitor'
        },
        {
          path: '/fixedManage',
          component: () => import('@/view/pages/deviceManage/fixedManage'), //固定设备管理
          name: 'fixedManage'
        },
        {
          path: '/sjManage',
          component: () => import('@/view/pages/deviceManage/sjManage'), //水井设备管理
          name: 'sjManage'
        },
        {
          path: '/fixedSetting',
          component: () => import('@/view/pages/deviceManage/setting'), //阀门设置
          name: 'fixedSetting'
        },
        {
          path: '/njglList',
          component: () => import('@/view/pages/njgl/njglList'), //农机管理-农机列表
          name: 'njglList'
        },
        {
          path: '/njgj',
          component: () => import('@/view/pages/njgl/njgj'), //农机管理-农机轨迹
          name: 'njgj'
        },
        {
          path: '/dzwl',
          component: () => import('@/view/pages/njgl/dzwl'), //农机管理-电子围栏
          name: 'dzwl'
        },
        {
          path: '/dzwlBind',
          component: () => import('@/view/pages/njgl/dzwlBind'), //农机管理-电子围栏绑定
          name: 'dzwlBind'
        },
        {
          path: '/sbgl',
          component: () => import('@/view/pages/njgl/sbgl'), //农机管理-设备管理
          name: 'sbgl'
        },
        {
          path: '/njgl',
          component: () => import('@/view/pages/njgl/njgl'), //农机管理-设备管理
          name: 'njgl'
        },
        {
          path: '/drawGjMock',
          component: () => import('@/view/pages/landManage/drawGjMock'), //手动模拟轨迹
          name: 'drawGjMock'
        },
        {
          path: '/temporaryWork',
          component: () => import('@/view/pages/plantingPlant/temporaryWork'), //临时任务
          name: 'temporaryWork'
        },
        {
          path: '/jixiao',
          component: () => import('@/view/pages/jixiao/index'), //绩效
          name: 'jixiao'
        },
        {
          path: '/landList',
          component: () => import('@/view/pages/land/index'), //地块管理
          name: 'landList'
        },
        {
          path: '/groupDetail',
          component: () => import('@/view/pages/land/groupDetail'), //地块分组详情
          name: 'groupDetail'
        },
        {
          path: '/landDetails',
          component: () => import('@/view/pages/land/landDetail'), //地块详情
          name: 'landDetails'
        },
        {
          path: '/contract',
          component: () => import('@/view/pages/contract/index'), //合同列表
          name: 'contract'
        },
        {
          path: '/contractIn',
          component: () => import('@/view/pages/contract/in'), //合同入库
          name: 'contractIn'
        },
        {
          path: '/contractStatistics',
          component: () => import('@/view/pages/contract/statistics'), //合同入库
          name: 'contractStatistics'
        },
        {
          path: '/contractGoods',
          component: () => import('@/view/pages/contract/bindGoods'), //合同列表
          name: 'contractGoods'
        },
        {
          path: '/facilitator',
          component: () => import('@/view/pages/facilitator/index'), //服务商
          name: 'facilitator'
        },
        {
          path: '/facilitatorVerify',
          component: () => import('@/view/pages/facilitator/verify'), //服务商
          name: 'facilitatorVerify'
        },
        {
          path: '/purchase',
          component: () => import('@/view/pages/purchase/index'), //采购
          name: 'purchase'
        },
        {
          path: '/uavRecordList',
          component: () => import('@/view/pages/uavManage/uavRecordList'), //无人机申请记录
          name: 'uavRecordList'
        },
        {
          path: '/uavPatrolList',
          component: () => import('@/view/pages/uavManage/uavPatrolList'), //无人机申请记录
          name: 'uavPatrolList'
        },
        {
          path: '/growthModel',
          component: () => import('@/view/pages/uavManage/growthModel'), //无人机模块长势分析模型
          name: 'growthModel'
        },
        {
          path: '/zhzl',
          component: () => import('@/view/pages/zhzl/index'), //灾害总览
          name: 'zhzl'
        },
        {
          path: '/traceability',
          name: 'traceability',
          component: () => import('@/view/pages/traceability/index')
        }
      ]
    }
  ]
});
//重复路由跳转报错问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  store.commit('set_breadList', []);
  const token = localStorage.getItem('token');
  if (to.name == 'screenLand') {
    next();
  } else {
    if (!token && to.name != 'login') {
      next({ path: 'login' });
    } else {
      if (to.meta && to.meta.title) {
        document.title = to.meta.title;
      }

      next();
    }
  }
});

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
router.onError((error) => {
  console.log(error, '路由错误');
});

export default router;
